import React, { useCallback, useEffect, useState } from 'react'
import Navbar from '../../../Components/navbar'
import "../../../CSS/CANoticeBoard.css"
import { Link } from 'react-router-dom'
import { backendURL, currentYear } from '../../../constant';
import CollegeNavbar from '../../../Components/CollegeNavbar';

function STNoticeboard() {
    const [detail, setDetail] = useState({});

    const callProfile = useCallback( async () => {
        try {
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
      
      
      
            const requestOptions = {
              method: "GET",
              headers: myHeaders,
      
              redirect: "follow"
            };
      
            fetch(`${backendURL}/getCollegesNoticeboard`, requestOptions)
              .then((response) => response.text())
              .then(async (result) => {
                // console.log("faculty", result);
      
                const data = JSON.parse(result);
                console.log(data);
                setDetail(data);

      
              })
              .catch((error) => console.error(error));
        } catch (err) {
            console.log(err);

        }
    },[setDetail]);

    const picurl = `${backendURL}/public/uploads/`;
    const renderPhotos = (photos) => {
        return photos.map((photo, index) => (
            <div key={index} className='col-md-6 col-12 p-1'>
                <img className='imgdesign img-fluid' src={picurl + photo} alt="photos"></img>
            </div>
        ));
    };
    useEffect(() => {
        callProfile();
    }, [callProfile]);
    return (
        <>
            <CollegeNavbar />
            <div className='CANoticeboardbg'>
                <div className=' container-fluid text-light filter10 text-center'>
                    <p >Home / Notice Board</p><br></br>

                    <h1 className='fw-bold d-sm-block d-none'>Notice Board</h1>
                    <h1 className='fw-bold fs-1 d-sm-none d-block'>Notice Board</h1>


                    {/* <p className='fs-2 fw-bold'>(COMPUTER BASED EXAM)</p> */}
                </div>
            </div>


            <div className='bg-lightgrey'>
                <div className='py-5 container   px-md-1 px-0' data-aos="flip-up">
                    <p className='mx-md-5 mx-1 text-center bgdarkblue rounded py-3  text-white'><span className='fw-bold fs-3'>Category Archives: </span><span className='fs-3'>Satellite Town Campus</span></p>
                </div>


                <div className='container-fluid pb-5 px-md-1 px-0 overflow-hidden'>
                    <div className='d-lg-flex ms-lg-5 ms-md-3 ms-0'>
                        <div data-aos="fade-left" className='col-lg-3 col-12 border border-bottom-0 border-end-0 border-top-0  order-lg-2 order-1'>
                            <p className='text-center fontcolordark fw-bold fs-3 bgdarkblue text-white mb-0'>Categories</p>

                            <ul className='list-unstyled text-primary '>

                                <Link className='text-decoration-none d-flex noticecat ps-3 pe-3 py-2 fs-6' to="/RARNoticeBoard">RAR Campus</Link>
                                <Link className='text-decoration-none d-flex  ps-3 pe-3 py-2 fs-6 noticecat' to="/F8IslamabadNoticeBoard">F8 Islamabad Noticeboard</Link>
                                <Link className='text-decoration-none d-flex noticeAtive ps-3 pe-3 py-2 fs-6' to="/SatelliteTownNoticeBoard">Satellite Town Noticeboard</Link>
                                <Link className='text-decoration-none d-flex noticecat ps-3 pe-3 py-2 fs-6' to="/ACCAPeshawarRoadNoticeBoard">Peshawar Road Noticeboard</Link>
                            
                            </ul>
                        </div>
                        <div className='col-lg-9 col-12 px-md-3 px-0 text-justify' data-aos="fade-right">
                            {Array.isArray(detail) && detail.length > 0 ? (
                                detail.map((ann, id) => {
                                    // Add a condition to check if the type is "rwp"
                                    if (ann.type === "Satellite Town Campus") {
                                        return (
                                            <div className='d-flex flex-wrap shadow-lg px-3 border border-start-0 border-top-0 border-end-0 py-4' data-aos="fade-up">
                                                <div className=' col-md-2 col-12 me-5'>
                                                    <div className='event_date'>
                                                        <div className=' text-center event-date-wrap text-white'><p className='fw-bold '>{ann.date}</p></div>

                                                    </div>
                                                </div>
                                                <div className='col-md-9 col-12 mt-md-0 mt-3'>
                                                    <h2>{ann.title}</h2>
                                                    <p>{ann.description}</p>
                                                    <div className='pb-4 d-flex flex-wrap justify-content-around'>
                        {ann.photos && renderPhotos(ann.photos.split(','))}
                    </div>
                                                </div>

                                            </div>
                                        )
                                    }
                                })) : (<p className='d-flex justify-content-center'><div class="spinner-border text-primary" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div></p>)}

                        </div>



                    </div>

                </div>



                <div className='bgdarkblue py-4 mt-4'>
                    <div className='d-flex justify-content-around text-white'>
                        <div>
                            © Copyright {currentYear} - All rights reserved
                        </div>
                        <div>
                        <span className='fw-bold'>SKANS</span> Group of Educational Institutes
                    </div>

                    </div>


                </div>
            </div>
        </>
    )
}

export default STNoticeboard
