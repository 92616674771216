import React, { useState } from 'react';
import "../CSS/navbarnew.css"
import { Link } from 'react-router-dom';
import logo from "../Photos/logo.png"
import logo1 from "../Photos/Skanslogo1.png"
import logo2 from "../Photos/Skanslogo2.png"
import logo3 from "../Photos/30yearslogo.png"
import GlobalSidebar from './GlobalSideBar';
import employability from "../Pdf/emp-ability.pdf"
import IsbPlatinumCertificate from "../Photos/IsbPlatinumCertificate.jpg"
import RwpPlatinumCertificate from "../Pdf/RwpPlatinumCertificate.pdf"
import ICAPfellowREAT from "../Photos/ICAPfellowREAT.jpg"
import CBELicense from "../Pdf/CBE licence SKANS Rawalpindi 2024-2025.pdf"
import CAAffiliation from "../Pdf/CA Affilliation certificate.pdf"
import GoldApprovedLeraningPartner from "../Pdf/SKANS - Gold Approved Learing Partner Certificate.pdf"
import STCampusCBELicence from "../Pdf/ST Campus CBE Licence.pdf"



import Popup1 from './Popup1';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import Popup2 from './Popup2';
import { backendURL } from '../constant';





function NavbarNew() {
    // const [isMenuDisplayed, setIsMenuDisplayed] = useState(false);

    // const toggleMenu = () => {
    //     setIsMenuDisplayed(!isMenuDisplayed);
    // };
    const [isOpen1, setIsOpen1] = useState(false);



    const togglePopup3 = () => {
        setIsOpen1(!isOpen1);
    }



    const [showMenu, setShowMenu] = useState(false);

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };


    const handleClose = () => setShowMenu(false);
    const handleShow = () => setShowMenu(true);

    const [Complaint, setComplaint] = useState({
        name: "",
        email: "",
        mobile: "",
        campus: "",
        complaint: ""
    });
    const [detail, setDetail] = useState({});

    const handleInput = (e) => {
        const { name, value } = e.target;
        setComplaint({ ...Complaint, [name]: value });
    };

    const handleDataUpload = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(`${backendURL}/ComplaintForm`, Complaint);
            console.log(response);
            toast.success("Your Complaint saved Successfully.", {
                position: "top-center",
                hideProgressBar: true,
                theme: "colored",
            });
            setComplaint({
                name: "",
                email: "",
                mobile: "",
                campus: "",
                complaint: ""
            }); // Clear the input fields after successful upload
        } catch (error) {
            console.error(error);
            toast.error("Failed to Upload Complaint", {
                position: "top-center",
                hideProgressBar: true,
                theme: "colored",
            });
        }
    };

    return (
        <>
            <ToastContainer />
            <div>

                <div class="offcanvas offcanvas-end bgdarkblue" data-bs-scroll="true" tabindex="-1" id="offcanvasWithBothOptions1" aria-labelledby="offcanvasWithBothOptionsLabel1">
                    <div class="offcanvas-header">

                        <div className='col-6'>
                            <img className='img-fluid' src={logo1}></img>
                        </div>

                        <button type="button" class="border border-0 bgdarkblue fs-4 text-white p-0" data-bs-dismiss="offcanvas" aria-label="Close"><i class="bi bi-x-lg"></i></button>
                    </div>
                    <div id='sidebar' className="offcanvas-body">



                        <ul className="list-unstyled components">
                            <li className="">
                                <a href="#pageSubmenu"
                                    data-bs-toggle="collapse"
                                    aria-expanded="false"
                                    className="d-flex justify-content-between pe-3 align-items-center" >Certificate &nbsp;&nbsp; <i className="bi bi-chevron-down"></i></a>
                                <ul className="collapse list-unstyled" id="pageSubmenu">
                                    <li className=""><a target="_blank" href={employability} style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>Employability</a></li>
                                    <li className=""><a target="_blank" href={IsbPlatinumCertificate} style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>SKANS ISLAMABAD Platinum ALP Certificate</a></li>
                                    <li className=""><a target="_blank" href={RwpPlatinumCertificate} style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>SKANS RAWALPINDI Platinum ALP Certificate</a></li>
                                    <li className=""><a target="_blank" href={CBELicense} style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>CBE licence SKANS Rawalpindi 2024-2025</a></li>
                                    <li className=""><a target="_blank" href={ICAPfellowREAT} style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>ICAP Fellow REAT Certificate</a></li>
                                    <li className=""><a target="_blank" href={CAAffiliation} style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>CA Affilliation Certificate</a></li>
                                    <li className=""><a target="_blank" href={STCampusCBELicence} style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>ST Campus CBE Licence</a></li>
                                    <li className=""><a target="_blank" href={GoldApprovedLeraningPartner} style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>SKANS - Gold Approved Learing Partner Certificate</a></li>



                                </ul>
                            </li>
                            <li className="">
                                <a href="#pageSubmenu1"
                                    data-bs-toggle="collapse"
                                    aria-expanded="false"
                                    className="d-flex justify-content-between pe-3 align-items-center">SKANS Login &nbsp;&nbsp; <i className="bi bi-chevron-down"></i></a>
                                <ul className="collapse list-unstyled" id="pageSubmenu1">
                                    <li className=""><Link to="/HowtouseStudentportal" style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>How to use our Student Portal</Link></li>
                                    <li className=""><Link to="http://58.65.172.36/stdlogin/login.aspx" style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>Student Portal</Link></li>
                                </ul>
                            </li>
                            <li className="">
                                <Link className="" to="http://58.65.172.36/cb%20result/login.aspx">Teacher Evaluation</Link>
                            </li>
                            <li className="">
                                <Link className="" to="/Applyonline">Apply Online</Link>
                            </li>
                            <li className="">
                                <a href="#pageSubmenu2"
                                    data-bs-toggle="collapse"
                                    aria-expanded="false"
                                    className="d-flex justify-content-between pe-3 align-items-center">CBE Practice &nbsp;&nbsp; <i className="bi bi-chevron-down"></i></a>
                                <ul className="collapse list-unstyled" id="pageSubmenu2">
                                    <li className=""><Link to="http://58.65.172.36/PRCcbe/default.aspx" style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>CA-PRC/AFC</Link></li>
                                    <li className=""><Link to="http://58.65.172.36/cafcbe/default.aspx" style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>CA-CAF</Link></li>
                                    <li className=""><Link to="http://58.65.172.36/fdacbe/default.aspx" style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>FDA/ACCA</Link></li>
                                    <li className=""><Link to="http://58.65.172.36/accacbe/default.aspx" style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>ACCA Skill Level</Link></li>
                                </ul>
                            </li>
                            <li className=''><a className='' href="http://58.65.172.36/WebSiteUpdates/NoticeBoard.aspx">CBE Dates</a></li>

                            <li className="">
                                <Link className="" onClick={togglePopup3}>Complaint box</Link>
                            </li>
                            <li><a className='fs-5' href='https://skans.pk/onlinepayments/challans.php'>Online Challan Payment</a></li>
                        </ul>

                    </div>
                </div>
            </div>







            <div className=' rounded-0 bgdarkblue pe-5-lg p-0 z-5 d-lg-block d-none'>
                <ul className='exo-menu list-unstyled d-flex justify-content-center  mb-0 '>

                    <li className="drop-down zindex5 border-primary border border-start-0 border-top-0 border-bottom-0">
                        <a className='px-2 py-1' >Certificates &nbsp;&nbsp; <i className='bi bi-chevron-down'></i></a>
                        <ul className="drop-down-ul animated fadeIn ">
                            <li className=""><a target="_blank" href={employability} style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>Employability</a>
                            </li>

                            <li className=""><a target="_blank" href={IsbPlatinumCertificate} style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>SKANS ISLAMABAD Platinum ALP Certificate</a>

                            </li>
                            <li className=""><a target="_blank" href={RwpPlatinumCertificate} style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>SKANS RAWALPINDI Platinum ALP Certificate</a>

                            </li>
                            <li className=""><a target="_blank" href={CBELicense} style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>CBE licence SKANS Rawalpindi 2024-2025</a></li>

                            <li className=""><a target="_blank" href={ICAPfellowREAT} style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>ICAP Fellow REAT Certificate</a>


                            </li>
                            <li className=""><a target="_blank" href={CAAffiliation} style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>CA Affilliation Certificate</a></li>
                                    <li className=""><a target="_blank" href={STCampusCBELicence} style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>ST Campus CBE Licence</a></li>
                                    <li className=""><a target="_blank" href={GoldApprovedLeraningPartner} style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>SKANS - Gold Approved Learing Partner Certificate</a></li>

                        </ul>
                    </li>

                    <li className="drop-down zindex5 border-primary border border-start-0 border-top-0 border-bottom-0">
                        <a className='px-2 py-1' >SKANS Login &nbsp;&nbsp; <i className='bi bi-chevron-down'></i></a>
                        <ul className="drop-down-ul animated fadeIn ">
                            <li className=""><Link to="/HowtouseStudentportal" style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>How to use our Student Portal</Link>
                            </li>

                            <li className=""><Link to="http://58.65.172.36/stdlogin/login.aspx" style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>Student Portal</Link>

                            </li>

                        </ul>
                    </li>



                    <li className='px-4 border-primary  border border-start-0 border-top-0 border-bottom-0 py-1'>
                        <Link className='text-decoration-none text-white' to="http://58.65.172.36/cb%20result/login.aspx">Teacher Evaluation</Link>

                    </li>
                    <li className='px-4 border-primary  border border-start-0 border-top-0 border-bottom-0 py-1'>
                        <Link className='text-decoration-none text-white' to="/Applyonline">Apply Online</Link>

                    </li>



                    <li className="drop-down zindex5 border-primary border border-start-0 border-top-0 border-bottom-0">
                        <a className='px-2 py-1' >CBE Practice &nbsp;&nbsp; <i className='bi bi-chevron-down'></i></a>
                        <ul className="drop-down-ul animated fadeIn ">
                            <li className=""><Link to="http://58.65.172.36/PRCcbe/default.aspx" style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>CA-PRC/AFC</Link></li>
                            <li className=""><Link to="http://58.65.172.36/cafcbe/default.aspx" style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>CA-CAF</Link></li>
                            <li className=""><Link to="http://58.65.172.36/fdacbe/default.aspx" style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>FDA/ACCA</Link></li>
                            <li className=""><Link to="http://58.65.172.36/accacbe/default.aspx" style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>ACCA Skill Level</Link></li>



                        </ul>
                    </li>
                    <li className='px-4 border-primary  border border-start-0 border-top-0 border-bottom-0 py-1'><a className='' href="http://58.65.172.36/WebSiteUpdates/NoticeBoard.aspx">CBE Dates</a></li>


                    <li className='px-4 border-primary  border border-start-0 border-top-0 border-bottom-0 py-1'>
                        <Link className='text-decoration-none text-white' onClick={togglePopup3}>Complaint box</Link>

                    </li>
                    <li className='px-4 border-primary  border border-start-0 border-top-0 border-bottom-0 py-1'><a style={{fontSize:"14px"}} href='https://skans.pk/onlinepayments/challans.php'>Online Challan Payment</a></li>

                </ul>


                {isOpen1 && <Popup2
                    content={<>
                        <div class="container">
                            <div class="row mx-0 justify-content-center">
                                <div class="col-12 ">
                                    <h3 className='fontcolordark fw-bold py-2'>COMPLAINT BOX</h3>
                                    <form
                                        method="POST"
                                        class="w-100 rounded-1 p-4 border bg-white"
                                        action=""
                                        enctype="multipart/form-data"
                                        onSubmit={handleDataUpload}
                                    >
                                        <label class="d-block mb-1">
                                            <span class="form-label d-block">Name</span>
                                            <input
                                                name="name"
                                                type="text"
                                                class="form-control"
                                                placeholder="Enter Name"
                                                value={Complaint.name}
                                                onChange={handleInput}
                                                required
                                            />
                                        </label>

                                        <label class="d-block mb-1">
                                            <span class="form-label d-block">Email address</span>
                                            <input
                                                name="email"
                                                type="email"
                                                class="form-control"
                                                placeholder="Enter Email Address"
                                                value={Complaint.email}
                                                onChange={handleInput}
                                                required
                                            />
                                        </label>
                                        <label class="d-block mb-1">
                                            <span class="form-label d-block">Phone No.</span>
                                            <input
                                                name="mobile"
                                                type="number"
                                                class="form-control"
                                                placeholder="Enter Phone Number"
                                                value={Complaint.mobile}
                                                onChange={handleInput}
                                                required
                                            />
                                        </label>

                                        <label class="d-block mb-1">
                                            <span class="form-label d-block">Campus</span>
                                            <input
                                                name="campus"
                                                type="text"
                                                class="form-control"
                                                placeholder="Enter Campus Name"
                                                value={Complaint.campus}
                                                onChange={handleInput}
                                                required
                                            />
                                        </label>



                                        <label class="d-block mb-1">
                                            <span class="form-label d-block">What's your Complaint?</span>
                                            <textarea
                                                name="complaint"
                                                class="form-control"
                                                rows="3"
                                                placeholder="Please describe your problem"
                                                value={Complaint.complaint}
                                                onChange={handleInput}
                                                required
                                            ></textarea>
                                        </label>

                                        <div class="mb-2">
                                            <button type="submit" class="btn btn-primary px-3 rounded-3">
                                                Submit
                                            </button>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>



                    </>}
                    handleClose={togglePopup3}

                />}


            </div>




            <div className='body1  bg-white  sticky-top zindex4'  >  {/*sticky-top */}

                <div className='container-fluid d-flex justify-content-between align-items-center position-relative py-lg-2 ps-lg-4 pb-lg-4 p-2 shadow-lg fw-bold fontcolor'>
                    <div className=' col-1 d-block d-lg-none '>

                        <a className=" menu-toggle-button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBothOptions1" aria-controls="offcanvasWithBothOptions1">
                            <i class="bi bi-list fs-1 fontcolordark"></i>

                        </a>
                    </div>
                    {/* <div className=' col-1 d-block d-lg-none '>
                        <a class="toggle-menu" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBothOptions" aria-controls="offcanvasWithBothOptions">
                            <i class="bi bi-grid-3x3-gap-fill fs-1 fontcolordark"></i>

                        </a>
                    </div> */}
                    <div className='col-md-2 col-3 mb-lg-3 mb-0'>
                        <img className='img-fluid' src={logo}></img>
                    </div>
                    <div className='d-flex justify-content-center col-md-2 col-3 mb-lg-3 mb-0'>
                        <img className='img-fluid' width={"75%"} src={logo3}></img>
                    </div>
                    <div className='col-md-2 col-3 mb-lg-3 mb-0'>
                        <img className='img-fluid' width={"80%"} src={logo2}></img>
                    </div>




                    <div className={`d-lg-block d-none positionabsolute container text-white`}>
                        <ul className={` exo-menu d-flex justify-content-around bgdarkblue  list-unstyled  px-0`}>

                            <li className="active pe-0"><Link className='px-3 py-4' to="/"> Home</Link></li>

                            {/* <li className="drop-down">
                                <a className='px-2 pt-4 pb- text-center' >
                                    <div className='d-flex flex-column justify-content-center text-center'>
                                        <div>
                                            CA / ACCA &nbsp;&nbsp;
                                        </div>
                                        <div>
                                            <i className='bi bi-chevron-down'></i>
                                        </div>
                                    </div>
                                </a>
                                <ul className="drop-down-ul animated fadeIn text-white">

                                    <li><Link to="/CAProgram">CA</Link></li>
                                    <li><Link to="/ACCAProgram">ACCA</Link></li>


                                    <li className="flyout-right"><a style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>Campuses Location &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i class="bi bi-chevron-right"></i></a>
                                        <ul className="animated fadeIn ps-0">
                                            <li><Link to="/F8Campus">F8 Campus</Link></li>
                                            <li><Link to="/SatelliteTownCampus">Sattelite Town Campus</Link></li>
                                            <li><Link to="/RARCAmpus">Raja Akram Road, Saddar Campus</Link></li>
                                            <li><Link to="/ACCAPeshawarRoadCampus">ACCA Peshawar Road Campus</Link></li>



                                        </ul>
                                    </li>

                                    <li className="flyout-right "><a style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>F8 Islamabad Faculty &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i class="bi bi-chevron-right"></i></a>
                                        <ul className="animated fadeIn ps-0">
                                            <li><Link to="/CAF8Faculty">CA Faculty</Link></li>
                                            <li><Link to="/ACCAF8Faculty">ACCA Faculty</Link></li>

                                        </ul>
                                    </li>

                                    <li className="flyout-right"><a style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>Raja Akram Road, Saddar Campus Faculty &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i class="bi bi-chevron-right"></i></a>
                                        <ul className="animated fadeIn ps-0">
                                            <li><Link to="/CA_RARFaculty">CA Faculty</Link></li>
                                            <li><Link to="/ACCA_RARFaculty">ACCA Faculty</Link></li>
                                        </ul>
                                    </li>


                                    <li className="flyout-right"><a style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>Satellite Campus Faculty &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i class="bi bi-chevron-right"></i></a>
                                        <ul className="animated fadeIn ps-0">
                                            <li><Link to="/CA_STFaculty">CA Faculty</Link></li>
                                            <li><Link to="/ACCA_STFaculty">ACCA Faculty</Link></li>
                                        </ul>
                                    </li>
                                    <li className="flyout-right"><a style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>Peshawar Road Campus Faculty &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i class="bi bi-chevron-right"></i></a>
                                        <ul className="animated fadeIn ps-0">
                                            
                                            <li><Link to="/ACCAPeshawarRoadFaculty">ACCA Faculty</Link></li>
                                        </ul>
                                    </li>

                                </ul>
                            </li> */}

                            {/* <li className="drop-down">
                                <a className='px-2 pt-4 pb-1 text-center' >
                                    <div className='d-flex flex-column justify-content-center text-center'>
                                        <div>
                                            Schools &nbsp;&nbsp;
                                        </div>
                                        <div>
                                            <i className='bi bi-chevron-down'></i>
                                        </div>
                                    </div>
                                </a>
                                <ul className="drop-down-ul animated fadeIn">
                                    <li className="flyout-right"><a >Our Campuses&nbsp;&nbsp;&nbsp;&nbsp;<i class="bi bi-chevron-right"></i></a>
                                        <ul className="animated fadeIn ps-0" style={{ width: "300px" }}>

                                            <li><Link className='p-2' to="/JuniorHarleyCampus">Harley Street Campus</Link></li>
                                           
                                            <li><Link className='p-2' to="/JuniorChakriRoad">Junior Chakri Road Campus </Link></li>
                                            <li><Link className='p-2' to="/JuniorWestridgeCampus">Junior Westridge Campus </Link></li>
                                            <li><Link className='p-2' to="/SeniorWestridgeCampus">Senior Westridge Campus</Link></li>
                                            <li><Link className='p-2' to="/GolraMorCampus">Golra Mor Campus</Link></li>
                                            <li><Link className='p-2' to="/PeshawarRoadCampus">Peshawar Road Campus</Link></li>
                                            <li><Link className='p-2' to="/PWDCampus">SKANS PWD Campus</Link></li>
                                            <li><Link className='p-2' to="/AirportCampus">SKANS AECHS Campus</Link></li>






                                        </ul>
                                    </li>

                                  
                                    <li><Link to="/ParentsCorner">Parents Corner</Link></li>
                                    <li><Link to="/Curriculum">Curriculum</Link></li>
                                    <li><Link to="/Facilities">Facilities</Link></li>
                                    <li><Link to="/ContactUs">Contact Us</Link></li>

                                </ul>
                            </li>

                            <li><Link className='px-2 py-4' to="http://58.65.172.36/WebSiteUpdates/NoticeBoard.aspx">CBE Dates</Link></li> */}

                            {/* <li className="drop-down">
                                <a className='px-2 pt-4 pb-1' >
                                    <div className='d-flex flex-column justify-content-center text-center'>
                                        <div>
                                            Calender &nbsp;&nbsp;
                                        </div>
                                        <div>
                                            <i className='bi bi-chevron-down'></i>
                                        </div>
                                    </div>
                                </a>
                                <ul className="drop-down-ul animated fadeIn">
                                    <li className="flyout-right"><a style={{ whiteSpace: 'nowrap' }}>F8 Islamabad Campus &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i class="bi bi-chevron-right"></i></a>
                                        <ul className="animated fadeIn ps-0">
                                            <li><Link to="/CAIsalamabadCalender">CA Calender</Link></li>
                                            <li><Link to="/ACCAIslamabadCalender">ACCA Calender</Link></li>
                                            <li><Link to="/EventIslamabad">F8 Islamabad Event </Link></li>
                                        </ul>
                                    </li>

                                    <li className="flyout-right"><a style={{ whiteSpace: 'nowrap' }}>Satellite Town Campus &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i class="bi bi-chevron-right"></i></a>
                                        <ul className="animated fadeIn ps-0">
                                            <li><Link to="/CASatelliteTownCalender">CA Calender</Link></li>
                                            <li><Link to="/ACCASatelliteTownCalender">ACCA Calender</Link></li>
                                            <li><Link to="/EventSatelliteTown">Satellite Town Event </Link></li>
                                        </ul>
                                    </li>


                                    <li className="flyout-right"><a style={{ whiteSpace: 'nowrap' }}>Raja Akram Road, Saddar Campus &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i class="bi bi-chevron-right"></i></a>
                                        <ul className="animated fadeIn ps-0">
                                            <li><Link to="/CA_RARCalender">CA Calender</Link></li>
                                            <li><Link to="/ACCA_RARCalender">ACCA Calender</Link></li>
                                            <li><Link to="/EventRAR">Raja Akram Road, Saddar Campus Event </Link></li>
                                        </ul>
                                    </li>

                                    <li className="flyout-right"><a style={{ whiteSpace: 'nowrap' }}>Peshawar Road Campus &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i class="bi bi-chevron-right"></i></a>
                                        <ul className="animated fadeIn ps-0">
                                            
                                            <li><Link to="/ACCAPeshawarRoadCalender">ACCA Calender</Link></li>
                                            <li><Link to="/EventPeshawarRoad">Peshawar Road Campus Event </Link></li>
                                        </ul>
                                    </li>

                                   
                                </ul>
                            </li> */}

                            <li><Link className='px-2 py-4' to="/SkansGallery">Life @ SKANS</Link></li>

                            {/* <li className="drop-down">
                                <a className='px-2 pt-4 pb-1' >
                                    <div className='d-flex flex-column justify-content-center text-center'>
                                        <div>
                                            Noticeboard &nbsp;&nbsp;
                                        </div>
                                        <div>
                                            <i className='bi bi-chevron-down'></i>
                                        </div>
                                    </div>
                                </a>
                                <ul className="drop-down-ul animated fadeIn">
                                    <li className="flyout-left"><a ><i class="bi bi-chevron-left">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</i>Colleges</a>
                                        <ul className="animated fadeIn ps-0">
                                            <li><Link to="/RARNoticeBoard">Raja Akram Road, Saddar Campus</Link></li>
                                            <li><Link to="/SatelliteTownNoticeBoard">Satellite Town Campus</Link></li>
                                            <li><Link to="/ACCAPeshawarRoadNoticeBoard">Peshawar Road Campus</Link></li>
                                            <li><Link to="/F8IslamabadNoticeBoard">F8 Islamabad Campus</Link></li>



                                        </ul>
                                    </li>

                                    <li className="flyout-left"><a ><i class="bi bi-chevron-left">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</i>Schools</a>
                                        <ul className="animated fadeIn" style={{ width: "300px" }}>
                                            <li><Link className='p-2' to="/HarleyStreetNoticeBoard">Harley Street Branch</Link></li>
                                            
                                            <li><Link className='p-2' to="/JuniorChakriRoadNoticeboard">Junior Campus Chakri Road</Link></li>
                                            <li><Link className='p-2' to="/JuniorWestridgeNoticeboard">Junior Campus Westridge</Link></li>
                                            <li><Link className='p-2' to="/SeniorWestridgeNoticeboard">Senior Campus Westridge</Link></li>
                                            <li><Link className='p-2' to="/GolraMorNoticeboard">Golra Mor Campus</Link></li>


                                            <li><Link className='p-2' to="/PeshawarRoadNoticeboard">Peshawar Road Campus</Link></li>
                                            <li><Link className='p-2' to="/AECHSNoticeboard">AECHS Campus</Link></li>
                                            <li><Link className='p-2' to="/PWDNoticeboard">PWD Campus</Link></li>

                                        </ul>
                                    </li>

                                </ul>
                            </li> */}

                            <li><Link className='px-2 py-4' to="/Meritstudents">High Achievers</Link></li>


                        </ul>


                        {/* <ul className={` exo-menu d-flex justify-content-around bgdarkblue  list-unstyled  px-0`}>

                            <li className="active pe-0"><Link className='px-3 py-4' to="/"> Home</Link></li>

                            <li className="drop-down">
                                <a className='px-2 pt-4 pb-1 text-center' >
                                    <div className='d-flex flex-column justify-content-center text-center'>
                                        <div>
                                            SKANS Schools of Accountancy - Colleges &nbsp;&nbsp;
                                        </div>
                                        <div>
                                            <i className='bi bi-chevron-down'></i>
                                        </div>
                                    </div>
                                </a>
                                <ul className="drop-down-ul animated fadeIn">



                                    <li className="flyout-right"><a style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>CA / ACCA &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i class="bi bi-chevron-right"></i></a>
                                        <ul className="animated fadeIn ps-0">
                                            <li><Link to="/CAProgram">CA</Link></li>
                                            <li><Link to="/ACCAProgram">ACCA</Link></li>


                                            <li className="flyout-right"><a style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>Campuses Location &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i class="bi bi-chevron-right"></i></a>
                                                <ul className="animated fadeIn ps-0">
                                                    <li><Link to="/F8Campus">F8 Campus</Link></li>
                                                    <li><Link to="/SatelliteTownCampus">Sattelite Town Campus</Link></li>
                                                    <li><Link to="/RARCAmpus">RAR Campus</Link></li>
                                                    <li><Link to="/ACCAPeshawarRoadCampus">ACCA Peshawar Road Campus</Link></li>



                                                </ul>
                                            </li>

                                            <li className="flyout-right"><a style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>F8 Islamabad Faculty &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i class="bi bi-chevron-right"></i></a>
                                                <ul className="animated fadeIn ps-0">
                                                    <li><Link to="/CAF8Faculty">CA Faculty</Link></li>
                                                    <li><Link to="/ACCAF8Faculty">ACCA Faculty</Link></li>

                                                </ul>
                                            </li>

                                            <li className="flyout-right"><a style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>RAR Campus Faculty &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i class="bi bi-chevron-right"></i></a>
                                                <ul className="animated fadeIn ps-0">
                                                    <li><Link to="/CA_RARFaculty">CA Faculty</Link></li>
                                                    <li><Link to="/ACCA_RARFaculty">ACCA Faculty</Link></li>
                                                </ul>
                                            </li>


                                            <li className="flyout-right"><a style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>Satellite Campus Faculty &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i class="bi bi-chevron-right"></i></a>
                                                <ul className="animated fadeIn ps-0">
                                                    <li><Link to="/CA_STFaculty">CA Faculty</Link></li>
                                                    <li><Link to="/ACCA_STFaculty">ACCA Faculty</Link></li>
                                                </ul>
                                            </li>
                                            <li className="flyout-right"><a style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>Peshawar Road Campus Faculty &nbsp;&nbsp;&nbsp;&nbsp;<i class="bi bi-chevron-right"></i></a>
                                                <ul className="animated fadeIn ps-0">

                                                    <li><Link to="/ACCAPeshawarRoadFaculty">ACCA Faculty</Link></li>
                                                </ul>
                                            </li>




                                        </ul>
                                    </li>


                                    <li><Link className='' to="http://58.65.172.36/WebSiteUpdates/NoticeBoard.aspx">CBE Dates</Link></li>


                                    <li className="flyout-right"><a style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>Calender &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i class="bi bi-chevron-right"></i></a>
                                        <ul className="animated fadeIn ps-0">
                                            <li className="flyout-right"><a style={{ whiteSpace: 'nowrap' }}>F8 Islamabad Campus &nbsp;&nbsp;&nbsp;<i class="bi bi-chevron-right"></i></a>
                                                <ul className="animated fadeIn ps-0">
                                                    <li><Link to="/CAIsalamabadCalender">CA Calender</Link></li>
                                                    <li><Link to="/ACCAIslamabadCalender">ACCA Calender</Link></li>
                                                    <li><Link to="/EventIslamabad">F8 Islamabad Event </Link></li>
                                                </ul>
                                            </li>

                                            <li className="flyout-right"><a style={{ whiteSpace: 'nowrap' }}>Satellite Town Campus &nbsp;&nbsp;<i class="bi bi-chevron-right"></i></a>
                                                <ul className="animated fadeIn ps-0">
                                                    <li><Link to="/CASatelliteTownCalender">CA Calender</Link></li>
                                                    <li><Link to="/ACCASatelliteTownCalender">ACCA Calender</Link></li>
                                                    <li><Link to="/EventSatelliteTown">Satellite Town Event </Link></li>
                                                </ul>
                                            </li>


                                            <li className="flyout-right"><a style={{ whiteSpace: 'nowrap' }}>RAR Campus &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i class="bi bi-chevron-right"></i></a>
                                                <ul className="animated fadeIn ps-0">
                                                    <li><Link to="/CA_RARCalender">CA Calender</Link></li>
                                                    <li><Link to="/ACCA_RARCalender">ACCA Calender</Link></li>
                                                    <li><Link to="/EventRAR">RAR Campus Event </Link></li>
                                                </ul>
                                            </li>

                                            <li className="flyout-right"><a style={{ whiteSpace: 'nowrap' }}>Peshawar Road Campus &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i class="bi bi-chevron-right"></i></a>
                                                <ul className="animated fadeIn ps-0">

                                                    <li><Link to="/ACCAPeshawarRoadCalender">ACCA Calender</Link></li>
                                                    <li><Link to="/EventPeshawarRoad">Peshawar Road Campus Event </Link></li>
                                                </ul>
                                            </li>


                                        </ul>
                                    </li>


                                    <li className="flyout-right"><a>Noticeboard &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i class="bi bi-chevron-right"></i></a>
                                        <ul className="animated fadeIn ps-0">
                                            <li><Link to="/RARNoticeBoard">RAR Campus</Link></li>
                                            <li><Link to="/SatelliteTownNoticeBoard">Satellite Town Campus</Link></li>
                                            <li><Link to="/ACCAPeshawarRoadNoticeBoard">Peshawar Road Campus</Link></li>
                                            <li><Link to="/F8IslamabadNoticeBoard">F8 Islamabad Campus</Link></li>



                                        </ul>
                                    </li>


                               

                                </ul>
                            </li>





                            <li className="drop-down">
                                <a className='px-2 pt-4 pb-1 text-center' >
                                    <div className='d-flex flex-column justify-content-center text-center'>
                                        <div>
                                            SKANS Schools &nbsp;&nbsp;
                                        </div>
                                        <div>
                                            <i className='bi bi-chevron-down'></i>
                                        </div>
                                    </div>
                                </a>
                                <ul className="drop-down-ul animated fadeIn">


                                    <li className="flyout-right"><a>Our Campuses &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i class="bi bi-chevron-right"></i></a>
                                        <ul className="animated fadeIn ps-0">
                                            <li><Link className='p-2' to="/JuniorHarleyCampus">Harley Street Campus</Link></li>

                                            <li><Link className='p-2' to="/JuniorChakriRoad">Junior Chakri Road Campus </Link></li>
                                            <li><Link className='p-2' to="/JuniorWestridgeCampus">Junior Westridge Campus </Link></li>
                                            <li><Link className='p-2' to="/SeniorWestridgeCampus">Senior Westridge Campus</Link></li>
                                            <li><Link className='p-2' to="/GolraMorCampus">Golra Mor Campus</Link></li>
                                            <li><Link className='p-2' to="/PeshawarRoadCampus">Peshawar Road Campus</Link></li>
                                            <li><Link className='p-2' to="/PWDCampus">SKANS PWD Campus</Link></li>
                                            <li><Link className='p-2' to="/AirportCampus">SKANS AECHS Campus</Link></li>


                                        </ul>
                                    </li>
                                    <li className="flyout-right"><a>Noticeboard &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i class="bi bi-chevron-right"></i></a>
                                        <ul className="animated fadeIn ps-0">
                                            <li><Link className='p-2' to="/HarleyStreetNoticeBoard">Harley Street Branch</Link></li>

                                            <li><Link className='p-2' to="/JuniorChakriRoadNoticeboard">Junior Campus Chakri Road</Link></li>
                                            <li><Link className='p-2' to="/JuniorWestridgeNoticeboard">Junior Campus Westridge</Link></li>
                                            <li><Link className='p-2' to="/SeniorWestridgeNoticeboard">Senior Campus Westridge</Link></li>
                                            <li><Link className='p-2' to="/GolraMorNoticeboard">Golra Mor Campus</Link></li>


                                            <li><Link className='p-2' to="/PeshawarRoadNoticeboard">Peshawar Road Campus</Link></li>
                                            <li><Link className='p-2' to="/AECHSNoticeboard">AECHS Campus</Link></li>
                                            <li><Link className='p-2' to="/PWDNoticeboard">PWD Campus</Link></li>



                                        </ul>
                                    </li>
                                    <li><Link to="/ParentsCorner">Parents Corner</Link></li>
                                    <li><Link to="/Curriculum">Curriculum</Link></li>
                                    <li><Link to="/Facilities">Facilities</Link></li>
                                    

                                    <li><Link to="/ContactUs">Contact Us</Link></li>

                                </ul>
                            </li>
                            <li><Link className='px-2 py-4' to="/SkansGallery">Life @ SKANS</Link></li>


                            <li><Link className='px-2 py-4' to="/Meritstudents">High Achievers</Link></li>

                        </ul> */}

                    </div>






























                    <div className='ms-3 col-1 d-block d-lg-none '>
                        {/* <a className='toggle-menu pt-2' type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBothOptions" aria-controls="offcanvasWithBothOptions"><i class="bi bi-grid-3x3-gap-fill fs-1 fontcolordark"></i></a> */}

                        {/* <a type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBothOptions" aria-controls="offcanvasWithBothOptions"><i class="bi bi-grid-3x3-gap-fill fs-1 fontcolordark"></i></a> */}
                        <GlobalSidebar />

                    </div>






                </div>
            </div>
            {/* <a href="#" className="toggle-menu " onClick={toggleMenu}><i class="bi bi-grid-3x3-gap-fill fs-1 fontcolordark"></i></a> */}
            {/* <div className='ms-3 col-1 d-block d-lg-none'>

                <a className='toggle-menu' onClick={toggleMenu} type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBothOptions" aria-controls="offcanvasWithBothOptions"><i class="bi bi-grid-3x3-gap-fill fs-1 fontcolordark"></i></a>

                <div class="offcanvas offcanvas-start bgdarkblue text-white" data-bs-scroll="true" tabindex="-1" id="offcanvasWithBothOptions" aria-labelledby="offcanvasWithBothOptionsLabel">
                    <div class="offcanvas-header">
                        <h5 class="offcanvas-title" id="offcanvasWithBothOptionsLabel">
                            Backdrop with scrolling
                            <div className=' col-6'>
                                <img className='img-fluid' src={logo1}></img>
                            </div>
                        </h5>
                        <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    </div>
                    <div class="offcanvas-body">

                        <p>Try scrolling the rest of the page to see this option in action.</p>
                    </div>
                </div>
            </div> */}
        </>
    );
}

export default NavbarNew;
